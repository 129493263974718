/*******************************
         Site Overrides
*******************************/
.ui.button.toggle.active {
    background-color: #feb938 !important;
}

.ui.button.toggle.active:hover {
    background-color: #fea705 !important;
}
